import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle, faInfo, faSignOutAlt, faClipboard, faClipboardList, faHistory, faTable } from '@fortawesome/free-solid-svg-icons'
import { faCompass, faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import "../App.css"

export const toGetComp = (formID) => {

    switch (formID) {
        case 'formecomm':
            return {
                constants: [{
                    'id': 'user',
                    'title': 'User View',
                    'position': 1
                },
                // {
                //     'id': 'ecomcust',
                //     'title': 'Active Customers',
                //     'position': 2
                // },
                {
                    'id': 'request-status',
                    'title': 'Request Status',
                    'position': 6
                },
                // {
                //     'id': 'ecomcustadmin',
                //     'title': 'Active Customers - Approval',
                //     'position': 3
                // },
                ],
                admin: [{
                    'id': 'consolidated',
                    'title': 'Consolidated',
                    'position': 4
                }, {
                    'id': 'approval',
                    'title': 'Approval',
                    'position': 5
                },
                 {
                    'id': 'categoryBrandMapping',
                    'title': 'Category Brand Mapping',
                    'position': 7
                },
                {
                    'id': 'eretailbp',
                    'title': 'BP Targets Setup',
                    'position': 8
                }
            ]
            }

            break;

        case 'emission':
            return {
                constants: [{
                    'id': 'user',
                    'title': '2017 BaseLine Target',
                    'position': 1
                },
                {
                    'id': 'emission_2030_targets',
                    'title': '2030 Roadmap Target',
                    'position': 2
                }
                ],
                admin: []
            }

            break;

        case 'formsku':
            return {
                constants: [{
                    'id': 'user',
                    'title': 'Exclusion List for CRI Calculation',
                    'position': 1
                },
                {
                    'id': 'consolidated',
                    'title': 'New launches and Delisting - YTD actual',
                    'position': 2
                },
                {
                    'id': 'approval',
                    'title': 'STM',
                    'position': 3
                }],
                admin: [
                    //     {
                    //     'id': 'consolidated',
                    //     'title': 'Consolidated'
                    // }
                ]
            }

            break;

        case 'formmap':
            return {
                constants: [{
                    'id': 'user',
                    'title': 'Brand',
                    'position': 1
                }, {
                    'id': 'consolidated',
                    'title': 'Channel',
                    'position': 2
                },
                {
                    'id': 'approval',
                    'title': 'Package',
                    'position': 3
                },
                {
                    'id': 'request-status',
                    'title': 'Company',
                    'position': 4
                }
                ],
                admin: []
            }

            break;
        case 'custhier':
            return {
                constants: [{
                    'id': 'user',
                    'title': 'Custom Hierarchy',
                    'position': 1
                }
                ],
                admin: []
            }

            break;



        case 'manualnum':
            return {
                constants: [{
                    'id': 'user',
                    'title': 'Manual Adjustment Best Estimates',
                    'position': 1
                },
                    // {
                    //     'id': 'request-status',
                    //     'title': 'Request Status',
                    //     'position': 4
                    // }
                ],
                admin: []
            }
            break;
        case 'priceforecast':
                return {
                    constants: [{
                        'id': 'user',
                        'title': 'Price Forecast',
                        'position': 1
                    },
                    ],
                    admin: []
                }
    
            break;

        default:
            return {
                constants: [{
                    'id': 'user',
                    'title': 'User View'
                }],
                admin: []
            }

    }

}

export const getNavLinkIcon = (navLink) => {
    switch (navLink) {
        case "eretailbp":
            return <FontAwesomeIcon icon={faTable} className="navlink-icon" size="2x" />;
            break;
        case 'user':
            return <FontAwesomeIcon icon={faUserCircle} className="navlink-icon" size="2x" />;
            break;
        case 'request-status':
            return <FontAwesomeIcon icon={faHistory} className="navlink-icon" size="2x" />;
            break;
        case 'emission_2030_targets':
            return <FontAwesomeIcon icon={faUserCircle} className="navlink-icon" size="2x" />;
            break;
        case 'consolidated':
            return <FontAwesomeIcon icon={faCompass} className="navlink-icon" size="2x" />;
            break;
        case 'approval':
            return <FontAwesomeIcon icon={faCheckCircle} className="navlink-icon" size="2x" />;
            break;
        case 'categoryBrandMapping':
            return <FontAwesomeIcon icon={faUserCircle} className="navlink-icon" size="2x" />;
            break;    
        default:
            return;
    }
}

export const getExternalLink = (form_id) => {
    switch (form_id) {
        case 'formecomm':
            // return "https://app.powerbi.com/groups/fbc5e91b-ce10-4bf6-b96b-545075dd243e/reports/f759d780-8677-451c-a5a5-575af6c9b8c5/ReportSection0e317ddce06e7edc0002?ctid=7a67a070-8ce9-4692-b1af-bf788306bc66";
            return {
                // 'link': "https://app.powerbi.com/groups/fbc5e91b-ce10-4bf6-b96b-545075dd243e/reports/f759d780-8677-451c-a5a5-575af6c9b8c5/ReportSection0e317ddce06e7edc0002?ctid=7a67a070-8ce9-4692-b1af-bf788306bc66",
                'link': process.env.REACT_APP_EXTERNAL_LINK_ECOMM,
                'title': 'eRetail & FSA'
            }
            break;
        case 'formsku':
            // return "https://app.powerbi.com/groups/24b87e87-f100-4744-b1e9-df9773014e55/reports/4a438683-b7d9-430f-bb28-be25d6728eed/ReportSectionaa7792ef7edfb873cb8b?ctid=7a67a070-8ce9-4692-b1af-bf788306bc66";
            return {
                // 'link': 'https://app.powerbi.com/groups/24b87e87-f100-4744-b1e9-df9773014e55/reports/4a438683-b7d9-430f-bb28-be25d6728eed/ReportSectionaa7792ef7edfb873cb8b?ctid=7a67a070-8ce9-4692-b1af-bf788306bc66',
                'link': process.env.REACT_APP_EXTERNAL_LINK_SKU,
                'title': 'SKU Performance'
            }
            break;
        case 'formmap':
            // return "https://app.powerbi.com/groups/24b87e87-f100-4744-b1e9-df9773014e55/reports/4a438683-b7d9-430f-bb28-be25d6728eed/ReportSectionaa7792ef7edfb873cb8b?ctid=7a67a070-8ce9-4692-b1af-bf788306bc66";
            return {
                // 'link': 'https://app.powerbi.com/groups/24b87e87-f100-4744-b1e9-df9773014e55/reports/4a438683-b7d9-430f-bb28-be25d6728eed/ReportSectionaa7792ef7edfb873cb8b?ctid=7a67a070-8ce9-4692-b1af-bf788306bc66',
                'link': process.env.REACT_APP_EXTERNAL_LINK_MFA,
                'title': 'CCH-The Edge'
            }
            break;
        case 'manualnum':
            // return "https://app.powerbi.com/groups/24b87e87-f100-4744-b1e9-df9773014e55/reports/4a438683-b7d9-430f-bb28-be25d6728eed/ReportSectionaa7792ef7edfb873cb8b?ctid=7a67a070-8ce9-4692-b1af-bf788306bc66";
            return {
                // 'link': 'https://app.powerbi.com/groups/24b87e87-f100-4744-b1e9-df9773014e55/reports/4a438683-b7d9-430f-bb28-be25d6728eed/ReportSectionaa7792ef7edfb873cb8b?ctid=7a67a070-8ce9-4692-b1af-bf788306bc66',
                'link': process.env.REACT_APP_EXTERNAL_LINK_MFA,
                'title': 'Manual Adjustment Best Estimates'
            }
            break;
        case 'custhier':
            // return "https://app.powerbi.com/groups/24b87e87-f100-4744-b1e9-df9773014e55/reports/4a438683-b7d9-430f-bb28-be25d6728eed/ReportSectionaa7792ef7edfb873cb8b?ctid=7a67a070-8ce9-4692-b1af-bf788306bc66";
            return {
                // 'link': 'https://app.powerbi.com/groups/24b87e87-f100-4744-b1e9-df9773014e55/reports/4a438683-b7d9-430f-bb28-be25d6728eed/ReportSectionaa7792ef7edfb873cb8b?ctid=7a67a070-8ce9-4692-b1af-bf788306bc66',
                // 'link': process.env.REACT_APP_EXTERNAL_LINK_MFA,
                // 'title': 'CCH-The Edge'
            }
            break;
        default:
            return {
                // 'link': "https://app.powerbi.com/groups/fbc5e91b-ce10-4bf6-b96b-545075dd243e/reports/f759d780-8677-451c-a5a5-575af6c9b8c5/ReportSection0e317ddce06e7edc0002?ctid=7a67a070-8ce9-4692-b1af-bf788306bc66",
                'link': process.env.REACT_APP_EXTERNAL_LINK_ECOMM,
                'title': 'eRetail & FSA'
            }
            break;
    }
}

export const getInfoDetails = (form_id) => {
    switch (form_id) {
        case 'formecomm':
            return {
                'enable': false,
                'description': "Description",
                'title': 'eRetail & FSA'
            }
            break;
        case 'formsku':
            return {
                'enable': true,
                'description': "Description",
                'title': 'SKU Performance'
            }
            break;
        case 'formmap':
            return {
                'enable': true,
                'description': "Description",
                'title': 'CCH-The Edge'
            }
            break;
        case 'custhier':
            return {
                'enable': true,
                'description': "Description",
                'title': ''
            }
            break;
        default:
            return {
                'enable': false,
                'description': "Description",
                'title': 'Title'
            }
            break;
    }

}

export const brandCategoryInitailColumnsBrandForm = [
    { id: 'brand', label: 'Brand', minWidth: 200, group: "brand_category" },
    { id: 'brand_key', label: 'Brand Key', minWidth: 200, group: "brand_category" },
    { id: 'summarized_brand', label: 'Summarized Brand', minWidth: 200, group: "brand_category" },
    {
        id: 'category_brand_key',
        label: 'Category Brand Key',
        minWidth: 200,
        align: 'right',
        group: "brand_category"
        // format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'category_sap',
        label: 'Category SAP',
        minWidth: 200,
        align: 'right',
        group: "brand_category"
        // format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'category',
        label: 'Category',
        minWidth: 150,
        align: 'right',
        group: "brand_category"
        // format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'brand_group',
        label: 'Brand Group',
        minWidth: 200,
        align: 'right',
        group: "brand_category"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'brand_group_l2',
        label: 'Brand Grp L2',
        minWidth: 200,
        align: 'right',
        group: "brand_category"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'brand_ranking',
        label: 'Brand Ranking',
        minWidth: 130,
        align: 'right',
        group: "brand_category"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'category_ranking',
        label: 'Category Ranking',
        minWidth: 130,
        align: 'right',
        group: "brand_category"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'trv_l2',
        label: 'TRV L2',
        minWidth: 150,
        align: 'right',
        group: "trv"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'trv_l3',
        label: 'TRV L3',
        minWidth: 150,
        align: 'right',
        group: "trv"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'trv_l4',
        label: 'TRV L4',
        minWidth: 150,
        align: 'right',
        group: "trv"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'trv_l5',
        label: 'TRV L5',
        minWidth: 150,
        align: 'right',
        group: "trv"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'trv_l6',
        label: 'TRV L6',
        minWidth: 150,
        align: 'right',
        group: "trv"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'trv_l7',
        label: 'TRV L7',
        minWidth: 200,
        align: 'right',
        group: "trv"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'trv_l3_ranking',
        label: 'TRV L3 Ranking',
        minWidth: 130,
        align: 'right',
        group: "trv"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'trv_l4_ranking',
        label: 'TRV L4 Ranking',
        minWidth: 130,
        align: 'right',
        group: "trv"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'trv_l5_ranking',
        label: 'TRV L5 Ranking',
        minWidth: 130,
        align: 'right',
        group: "trv"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'trv_l6_ranking',
        label: 'TRV L6 Ranking',
        minWidth: 130,
        align: 'right',
        group: "trv"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'trv_l7_ranking',
        label: 'TRV L7 Ranking',
        minWidth: 130,
        align: 'right',
        group: "trv"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'innovation',
        label: 'Innovation',
        minWidth: 50,
        align: 'right',
        group: "trv"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'tccc_indicator_sap',
        label: 'TCCC Indicator SAP',
        minWidth: 150,
        align: 'right',
        group: "dmi"

        // format: (value) => value.toFixed(2),
    },
    {
        id: 'sum_brand_ko',
        label: 'Sum brand KO',
        minWidth: 200,
        align: 'right',
        group: "dmi"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'category_vr',
        label: 'Category VR',
        minWidth: 150,
        align: 'right',
        group: "dmi"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'added_by',
        label: 'Added By',
        minWidth: 235,
        align: 'right',
        group: "other"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'updated_by',
        label: 'Updated By',
        minWidth: 235,
        align: 'right',
        group: "other"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'updated_at',
        label: 'Updated At',
        minWidth: 235,
        align: 'right',
        group: "other"
        // format: (value) => value.toFixed(2),
    },
    // {
    //     id: 'brand_category_ko',
    //     label: 'Brand Category KO',
    //     minWidth: 150,
    //     align: 'right',
    //     is_sortable: true,
    //     is_sorted: false,
    //     sort_type: "asc",
    //     sort_name: "brand_category_ko",
    //     // format: (value) => value.toFixed(2),
    // },
    {
        id: 'action',
        label: '',
        minWidth: 50,
        align: 'right',
        group: "other"
        // format: (value) => value.toFixed(2),
    },
];
export const channelCategoryInitailColumnsForm = [
    {
        id: 'bic_ccusthie4',
        label: 'Bic ccusthie4',
        minWidth: 200,
        align: 'right',
        group: "channel_category"
        // format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'home_ooh',
        label: 'Home ooh',
        minWidth: 150,
        align: 'right',
        group: "channel_category"
        // format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'trade_channel_ch3',
        label: 'Trade Channel ch3',
        minWidth: 200,
        align: 'right',
        group: "channel_category"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'subtrade_channel_ch4',
        label: 'Subtrade Channel ch4',
        minWidth: 200,
        align: 'right',
        group: "channel_category"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'channel_vr',
        label: 'Channel vr',
        minWidth: 130,
        align: 'right',
        group: "channel_category"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'added_by',
        label: 'Added By',
        minWidth: 235,
        align: 'right',
        group: "other"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'updated_by',
        label: 'Updated By',
        minWidth: 235,
        align: 'right',
        group: "channel_category"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'updated_at',
        label: 'Updated At',
        minWidth: 235,
        align: 'right',
        group: "channel_category"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'action',
        label: '',
        minWidth: 50,
        align: 'right',
        group: "other"
        // format: (value) => value.toFixed(2),
    },

];

export const packageInitailColumnsForm = [
    {
        id: 'package_size',
        label: 'Package Size',
        minWidth: 150,
        align: 'right',
        group: "package_mapping"
        // format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'package_type',
        label: 'Package Type',
        minWidth: 200,
        align: 'right',
        group: "package_mapping"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'package_key',
        label: 'Package Key',
        minWidth: 200,
        align: 'right',
        group: "package_mapping"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'final_package',
        label: 'Final Package',
        minWidth: 130,
        align: 'right',
        group: "package_mapping"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'reported_pack',
        label: 'Reported Package',
        minWidth: 150,
        align: 'right',
        group: "package_mapping"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'pack_type_1',
        label: 'PackType 1',
        minWidth: 200,
        align: 'right',
        group: "package_mapping"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'pack_type_2',
        label: 'PackType 2',
        minWidth: 150,
        align: 'right',
        group: "package_mapping"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'rank',
        label: 'Rank',
        minWidth: 150,
        align: 'right',
        group: "channel_category"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'added_by',
        label: 'Added By',
        minWidth: 235,
        align: 'right',
        group: "other"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'updated_by',
        label: 'Updated By',
        minWidth: 235,
        align: 'right',
        group: "channel_category"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'updated_at',
        label: 'Updated At',
        minWidth: 235,
        align: 'right',
        group: "channel_category"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'action',
        label: '',
        minWidth: 50,
        align: 'right',
        group: "other"
        // format: (value) => value.toFixed(2),
    },

];

export const dimCompanyInitailColumnsForm = [
    {
        id: 'region_sequence_id',
        label: 'Region Seq ID',
        minWidth: 150,
        align: 'right',
        group: ""
        // format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'region',
        label: 'Region',
        minWidth: 200,
        align: 'right',
        group: ""
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'market_segment',
        label: 'Market Segment',
        minWidth: 200,
        align: 'right',
        group: ""
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'business_unit',
        label: 'Business Unit',
        minWidth: 130,
        align: 'right',
        group: ""
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'business_unit_abbreviation',
        label: 'Business Unit Abbr',
        minWidth: 150,
        align: 'right',
        group: ""
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'country',
        label: 'Country',
        minWidth: 150,
        align: 'right',
        group: ""
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'country_abbreviation',
        label: 'Country Abbr',
        minWidth: 100,
        align: 'right',
        group: ""
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'geo_country',
        label: 'Geo Country',
        minWidth: 150,
        align: 'right',
        group: ""
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'geo_country_abbreviation',
        label: 'Geo Country Abbr',
        minWidth: 150,
        align: 'right',
        group: ""
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'co_code_bw',
        label: 'CO Code BW',
        minWidth: 150,
        align: 'right',
        group: ""
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'country_sequence_id',
        label: 'Country Seq ID',
        minWidth: 150,
        align: 'right',
        group: ""
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'business_unit_sequence_id',
        label: 'Business Unit Seq ID',
        minWidth: 150,
        align: 'right',
        group: ""
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'added_by',
        label: 'Added By',
        minWidth: 235,
        align: 'right',
        group: "other"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'updated_by',
        label: 'Updated By',
        minWidth: 235,
        align: 'right',
        group: "channel_category"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'updated_at',
        label: 'Updated At',
        minWidth: 235,
        align: 'right',
        group: "channel_category"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'action',
        label: '',
        minWidth: 50,
        align: 'right',
        group: "other"
        // format: (value) => value.toFixed(2),
    },

];

export const skuExclusionNewlistAndDelistInitialColumnsForm = [
    // {
    //     id: 'id',
    //     label: 'ID',
    //     minWidth: 200,
    //     align: 'right',
    //     group: "channel_category"
    //     // format: (value) => value.toLocaleString('en-US'),
    // },
    {
        id: 'quarter',
        label: 'Quarter',
        minWidth: 100,
        align: 'right',
        group: "channel_category"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'business_unit_name',
        label: 'Business Unit',
        minWidth: 150,
        align: 'right',
        group: "channel_category"
        // format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'type_title',
        label: 'Type',
        minWidth: 100,
        align: 'right',
        group: "channel_category"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'item_category',
        label: 'Item Category',
        minWidth: 130,
        align: 'right',
        group: "channel_category"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'ban_description',
        label: 'BAN Description',
        minWidth: 300,
        align: 'right',
        group: "other"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'ban_number',
        label: 'BAN Number',
        minWidth: 200,
        maxWidth: 200,
        align: 'center',
        is_sortable: true,
        is_sorted: false,
        sort_type: "asc",
        sort_name: "ban_number",
        // format: (value) => value.toLocaleString('en-US'),
    },
    // {
    //     id: 'ban',
    //     label: 'BAN',
    //     minWidth: 300,
    //     align: 'right',
    //     group: "other"
    //     // format: (value) => value.toFixed(2),
    // },
    // {
    //     id: 'material_description',
    //     label: 'Material Description',
    //     minWidth: 235,
    //     align: 'right',
    //     group: "other"
    //     // format: (value) => value.toFixed(2),
    // },
    // {
    //     id: 'material',
    //     label: 'Material',
    //     minWidth: 300,
    //     align: 'right',
    //     group: "other"
    //     // format: (value) => value.toFixed(2),
    // },
    {
        id: 'category_title',
        label: 'Category',
        minWidth: 150,
        align: 'right',
        group: "other"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'new_quarter',
        label: 'Quarter(Newlist/Delist)',
        minWidth: 100,
        align: 'right',
        group: "channel_category"
        // format: (value) => value.toFixed(2),
    },
    // {
    //     id: 'updated_by',
    //     label: 'Updated By',
    //     minWidth: 235,
    //     align: 'right',
    //     group: "channel_category"
    //     // format: (value) => value.toFixed(2),
    // },
    // {
    //     id: 'updated_at',
    //     label: 'Updated At',
    //     minWidth: 235,
    //     align: 'right',
    //     group: "channel_category"
    //     // format: (value) => value.toFixed(2),
    // },
    {
        id: 'action',
        label: '',
        minWidth: 50,
        align: 'right',
        group: "other"
        // format: (value) => value.toFixed(2),
    },

];

export const skuSTMInitialColumnsForm = [
    {
        id: 'quarter',
        label: 'Quarter',
        minWidth: 100,
        align: 'right',
        group: "channel_category"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'business_unit_name',
        label: 'Business Unit',
        minWidth: 150,
        align: 'right',
        group: "channel_category"
        // format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'project_description',
        label: 'Project Description',
        minWidth: 250,
        align: 'right',
        group: "channel_category"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'actual_launch_date',
        label: 'Actual Launch Date',
        minWidth: 100,
        align: 'right',
        group: "channel_category"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'is_on_time',
        label: 'On Time',
        minWidth: 100,
        align: 'right',
        group: "other"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'delay_reason',
        label: 'Delay Reason',
        minWidth: 200,
        align: 'right',
        group: "other"
        // format: (value) => value.toFixed(2),
    },
    // {
    //     id: 'material_description',
    //     label: 'Material Description',
    //     minWidth: 235,
    //     align: 'right',
    //     group: "other"
    //     // format: (value) => value.toFixed(2),
    // },
    {
        id: 'project_duration',
        label: 'Project Duration(In Weeks)',
        minWidth: 150,
        align: 'right',
        group: "other"
        // format: (value) => value.toFixed(2),
    },
    {
        id: 'action',
        label: '',
        minWidth: 50,
        align: 'right',
        group: "other"
        // format: (value) => value.toFixed(2),
    },

];