import React, { FC, useEffect, useState } from "react";
import { Col, Row, Typography, Spin } from "antd";

import "../../../App.css";
import DropDownCustom from "../../../components/dropDownCustom";
import DialogueBox from "../../../components/dialogueBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import {
  BASE_URL,
  EDGE_ECOMM,
  EDIT_CHECK,
  INPUT_FORMS,
  BEYOND_CORE_ASSORTMENT,
} from "../../../constant/apiEndpoints";
import APIService from "../../../services/APIService";
import { IBeyondRawData, ICountry, IDateListItem, IKBI, IMessage, KBIInputState } from "../../../reducer/ECommRetail/_interfaces";
import { IAPIReturn } from "../../../services/_interfaces";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../actions/eComm/eCommRetailFSAActions";
import * as selectors from "../../../reducer/ECommRetail/selectors";
import BeyondCoreCard from "./components/BeyondCoreCard";
import KBICard from "./components/KBICard";

type IProps = {
  formId: string;
}

type IDropDownType = 'Country' | 'Period';

const ERetailFSAPage:FC<IProps> = ({formId}) => {
  const dispatch = useDispatch();

  const message:IMessage = useSelector(selectors.message);

  const countries:ICountry[] = useSelector(selectors.countries);
  const countriesLoading:boolean = useSelector(selectors.countriesLoading);

  const periods:IDateListItem[] = useSelector(selectors.periods);
  const periodsLoading:boolean = useSelector(selectors.periodsLoading);
  
  const kbiMaster:IKBI[] = useSelector(selectors.kbiMaster);
  const kbiInputUnedited = useSelector(selectors.kbiMasterUnedited);
  const kbiMasterLoading:boolean = useSelector(selectors.kbiMasterLoading);
  
  const beyondRawTable:IBeyondRawData[] = useSelector(selectors.beyondRawTable);
  const beyondRawTableLoading:boolean = useSelector(selectors.beyondRawTableLoading);

  const beyondCoreKBIInputs:KBIInputState = useSelector(selectors.beyondCoreKBIInputs);

  const comment:string = useSelector(selectors.comment);
  const lastEditedBy:{lastUpdatedAt:string, lastAddedBy:string} = useSelector(selectors.lastEditedBy);
  const kbiValues:KBIInputState = useSelector(selectors.kbiValues);

  const [isLoadingUi, setIsLoadingUi] = useState<boolean>(false);

  const [selectedCountryID, setSelectedCountryID] = useState<string>("");
  const [selectedCountryTitle, setSelectedCountryTitle] = useState<string>("");
  const [selectedPeriodID, setSelectedPeriodID] = useState<string>("");
  const [selectedPeriodTitle, setSelectedPeriodTitle] = useState<string>("");
  const [selectedPrevPeriodTitle, setSelectedPrevPeriodTitle] = useState<string>("");
  const [inputDisabled, setInputDisabled] = useState(true);
  const [openDialogueBox, setOpenDialogueBox] = useState<boolean>(false);

  const [bpData, setBPData] = useState<any>(null);

  useEffect(() => {
    if (message) handleDialogueBox();
  }, [message]);

  useEffect(() => {
    if (!kbiMasterLoading && kbiMaster) {
      let kbiState:KBIInputState = {};
      kbiMaster.forEach((kbi:IKBI) => {
        kbiState[kbi.kbi_id] = -1;
      });

      dispatch(actions.setKBIInputValues(kbiState));
    }
  }, [kbiMaster, kbiMasterLoading]);

  useEffect(() => {
    dispatch(actions.clearBP());
    dispatch(actions.setBeyondCore({}));
    dispatch(actions.setKBIInputValuesPrevPeriod({}))
  }, [document.location.href])

  //Dynamic Input onChange Handler
  const handleCommentOnChange = (e:React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(actions.setComment(e.target.value))
  };

  const formatKbiInput = () => {
    let kbis = kbiValues;

    let newKbis:{kbi_id:string; kbi_value:number | undefined}[] = [];
    const keys = Object.keys(kbis);

    keys.forEach((key) => {
      newKbis.push({
        kbi_id: key,
        kbi_value: kbis[key] ?? undefined,
      });
    });

    return newKbis;
  };

  //Dropdown Handler As Parent Component
  const setDropDownValues = async (id:string, title:string, valueType:IDropDownType) => {
   
    if (valueType === "Country") {
      setSelectedCountryID(id);
      setSelectedCountryTitle(title);
    } else if (valueType === "Period") {
      setSelectedPeriodID(id);
      setSelectedPeriodTitle(title);

      const selected_date = periods.findIndex((x) => x.id === parseInt(id));
      if (periods[selected_date + 1]) {
        setSelectedPrevPeriodTitle(periods[selected_date + 1].title)
      }

    }
    setInputDisabled(true); //On Every Country And Period Change Edit Button Should Be Disabled

    //Loading KBI Values which is dependent on Country and Period Dropdown
    if (selectedPeriodID || selectedCountryID) {
      //Check if one of Country/Period selected
      if (selectedPeriodID && valueType === "Country") {
        dispatch(actions.loadData(selectedPeriodID, id, title, selectedPeriodTitle, formId, periods, beyondRawTable, kbiMaster));
      } else if (selectedCountryID && valueType === "Period") {
        dispatch(actions.loadData(id, selectedCountryID, selectedCountryTitle, title, formId, periods, beyondRawTable, kbiMaster));
      }
    }
  };

  useEffect(() => {
    if (!openDialogueBox) dispatch(actions.setMessage(null));
  }, [openDialogueBox])

  const handleDialogueBox = () => {
    setOpenDialogueBox(!openDialogueBox);
  };

  //Handling Edit Button
  const handleEdit = async () => {
    //Country and Period required validation
    if (selectedCountryID !== "" && selectedPeriodID !== "") {
      if (inputDisabled) {
        let selected_date = periods.find(({id}) => id === parseInt(selectedPeriodID));
        let url =
          BASE_URL +
          INPUT_FORMS +
          EDGE_ECOMM +
          EDIT_CHECK +
          `?selected_form=${formId}&selected_country=${selectedCountryID}&selected_year=${selected_date?.year}&selected_period=${selected_date?.month}`;
        let response:IAPIReturn= await new APIService().get(url);

        if (response.error) {
          console.log("error in user datelis API", response.results.message);
        } else {
          if (response.results.is_editable === true) {
            setInputDisabled(!inputDisabled);
            dispatch(actions.setComment("")); //Business Requirement To Empty Comment Box On Form Edit.
          } else {
            dispatch(actions.setMessage({
              type: "Error",
              text: "Previous update request for selected country and period is pending with Admin for approval. Data entry not allowed for now."
            }));
          }
        }
      } else {
        setInputDisabled(!inputDisabled);
      }
    } else {
      dispatch(actions.setMessage({
        type: "Error",
        text: "Select Country and Period first to enter data."
      }));
    }
  };

  const getRequestBody = async () => {
    let selected_date = periods.find(({id}) => id === parseInt(selectedPeriodID));

    let newKbis:{kbi_id:string, kbi_value:number}[] = [];
    const keys = Object.keys(kbiInputUnedited);
    keys.forEach((key, index) => {
      newKbis.push({
        kbi_id: key,
        kbi_value: parseFloat(String(kbiValues[key])),
      });
    });

    let count = 0;
    newKbis.forEach((newKbi) => {
      const element = newKbi.kbi_value;
      if (isBlank(element)) {
        count += 1;
      }
    });

    let body:any = {};
    if (count > 0) {
      body = null;
    } else {
     body = {
      country_id: selectedCountryID,
      form_id: formId,
      year: selected_date?.year,
      period: selected_date?.month,
      kbi_inputs: formatKbiInput(),
    };
    if (comment !== "") {
      body.comment = comment;
    }
  }
  
  return body;
  };

  function isBlank(str:number | string) {
    if (str === 0) {
      return false;
    } else {
      return !str || /^\s*$/.test(String(str));
    }
  }
  //New
  const formatKbiInputCategoryBrand = (kbis:KBIInputState) => {
    let newKbis:{kbi_id:string, kbi_value:number}[] = [];
    const keys = Object.keys(kbis);
    keys.forEach((key, index) => {
      newKbis.push({
        kbi_id: key,
        kbi_value: parseFloat(String(kbis[key])),
      });
    });
    let count = 0;
    newKbis.forEach((newKbi) => {
      const element = newKbi.kbi_value;
      if (isBlank(element)) {
        count += 1;
      }
    });

    if (count > 0) {
      setIsLoadingUi(false);
      return null;
    } else {
      return newKbis;
    }
  };
  const getRequestBodyCategoryBrand = async () => {
    const selected_date = periods.find(({id}) => id === parseInt(selectedPeriodID));
    const values = await formatKbiInputCategoryBrand(beyondCoreKBIInputs);
    let body:any = {};
    if (values == null) {
      body = null;
    } else {
      body = {
        country_id: selectedCountryID,
        form_id: formId,
        year: selected_date?.year,
        period: selected_date?.month,
        kbi_inputs: values,
      };
      if (comment !== "") {
        body.comment = comment;
      }
    }
    return body;
  };
  const onSubmit = async (e:React.FormEvent) => {
    e.preventDefault();
    setIsLoadingUi(true);
    const url = BASE_URL + INPUT_FORMS + EDGE_ECOMM;
    const urlTwo = BASE_URL + INPUT_FORMS + EDGE_ECOMM + BEYOND_CORE_ASSORTMENT;
    const valuesBeyond = await getRequestBodyCategoryBrand();
    const valuesKbi = await getRequestBody();

    if (valuesBeyond == null || valuesKbi == null) { 
      setIsLoadingUi(false);
      dispatch(actions.setMessage({
        type: 'Error',
        text: 'All fields are required and data must be valid!'
      }));
    } else {
      const response:IAPIReturn = await new APIService().post(url, valuesKbi);
      const responseTwo:IAPIReturn = await new APIService().post(urlTwo, valuesBeyond);

      if (response.error || responseTwo.error) {
        if (response.status === 400 || responseTwo.status) {
          setIsLoadingUi(false);
          if (
            response.results?.message?.includes("Comment") ||
            responseTwo.results?.message?.includes("Comment")
          ) {
            dispatch(actions.setMessage({
              type: 'Error',
              text: 'Comment field is mandatory for historical data. Please add to proceed.'
            }));
          } else {
            dispatch(actions.setMessage({
              type: 'Error',
              text: 'All fields are required and data must be valid!'
            }));
          }
        }
      } else {
        setIsLoadingUi(false);
        if (response.results.is_request_raised) {
          dispatch(actions.setMessage({
            type: 'Success',
            text: 'Update request for historical data has been successfully submitted to Admin for approval.'
          }));
        } else {
          dispatch(actions.setMessage({
            type: 'Success',
            text: 'Data has been saved successfully.'
          }));
        }
        setInputDisabled(!inputDisabled);
      }
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch(actions.loadCountries(formId));
      dispatch(actions.loadPeriods());
      dispatch(actions.loadKBIMaster(formId));
      dispatch(actions.loadBeyondCoreRawTable(formId));
    }
    return function cleanup() {
      mounted = false;
    };
  }, []);

  return (
    <Spin tip="Loading" spinning={isLoadingUi} size="large">
      {" "}
      <div className="user-view-container">
        <form onSubmit={onSubmit}>
          {/* Body Header */}
          <div className="user-view-header row p-0 m-0 mb-4">
            <div className="col-5 p-0 m-0 row">
              <div className="col-5 p-0 m-0">
                <DropDownCustom
                  title="Country"
                  options={countries}
                  loading={countriesLoading}
                  setParentDropdownValue={setDropDownValues}
                />
              </div>
              <div className="col-5 p-0 m-0">
                <DropDownCustom
                  title="Period"
                  options={periods}
                  loading={periodsLoading}
                  setParentDropdownValue={setDropDownValues}
                />
              </div>
            </div>
            <div className="col-3 p-0 m-0 my-auto user-view-added-at-container">
              <span>
                <label className="user-view-text-header">Last Updated At</label>
              </span>
              <span>
                <label className="user-view-text-value">{lastEditedBy.lastUpdatedAt}</label>
              </span>
            </div>
            <div className="col-4 p-0 m-0 my-auto user-view-added-by-container">
              <span>
                <label className="user-view-text-header">
                  Last Submitted By
                </label>
              </span>
              <span>
                <label className="user-view-text-value">{lastEditedBy.lastAddedBy}</label>
              </span>
            </div>
          </div>
          {/* Body Header */}
          {/* Input Tags */}
          <Row style={{ height: "60px", paddingLeft: "20px" }}>
            <Col span={12}>
              <Row>
                <Col span={5}>
                  <p className="user-view-input-label-text">Country</p>
                </Col>
                <Col span={13}>
                  <input
                    className="text-input-box-disabled"
                    type="text"
                    value={
                      selectedCountryTitle === "--Select--"
                        ? ""
                        : selectedCountryTitle
                    }
                    disabled
                  />
                </Col>
                <Col span={6}></Col>
              </Row>
            </Col>

            <Col span={12}>
              <Row justify="end">
                <FontAwesomeIcon
                  icon={faEdit}
                  className="navlink-icon"
                  onClick={handleEdit}
                  size="2x"
                  style={{ color: inputDisabled ? "#E61C2A" : "#9B9595" }}
                />
              </Row>
            </Col>
          </Row>
          <Row style={{ height: "60px", paddingLeft: "20px" }}>
            <Col span={12}>
              <Row>
                <Col span={5}>
                  <p className="user-view-input-label-text">Period</p>
                </Col>
                <Col span={13}>
                  <input
                    className="text-input-box-disabled"
                    type="text"
                    value={
                      selectedPeriodTitle === "--Select--"
                        ? ""
                        : selectedPeriodTitle
                    }
                    disabled
                  />
                </Col>
                <Col span={6}></Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row justify="center">
                <Typography.Title level={4} style={{ color: "#E61C2A" }}>
                  Beyond The Core Assortment
                </Typography.Title>
                
              </Row>
            </Col>
          </Row>
          {/* KBI Input Tags For Loop(Dynamic) */}
          <Row gutter={16}>
            <KBICard inputDisabled={inputDisabled} prevPeriodTitle={selectedPrevPeriodTitle} />
            <BeyondCoreCard inputDisabled={inputDisabled} prevPeriodTitle={selectedPrevPeriodTitle} />
          </Row>

          {/* KBI Input Tags For Loop(Dynamic) */}
          {/* Comment Box */}
          <Row
            style={{ height: "60px", paddingLeft: "20px", paddingTop: "10px" }}
          >
            <Col span={2}>
              <p className="user-view-input-label-text-comment">Comment</p>
            </Col>
            <Col span={14}>
              <textarea
                className="text-input-textarea"
                name="w3review"
                rows={4}
                cols={50}
                disabled={inputDisabled}
                value={comment}
                onChange={handleCommentOnChange}
              />
            </Col>
            <Col span={8}></Col>
          </Row>
          {/* Comment Box */}
          {/* Input Tags */}
          {/* Submit Button */}
          <div className="user-view-input-layer-submit row pr-3 m-0 ">
            <div className="col-12 m-0">
              <input
                className="form-submit-button col"
                type="submit"
                value="Submit"
                disabled={inputDisabled}
                style={{
                  backgroundColor: !inputDisabled ? "#E61C2A" : "#9B9595",
                }}
              />
            </div>
          </div>
          {/* Submit Button */}
        </form>
        {/* Message Dialogue Box */}

        
        
        <DialogueBox
          openDialogueBox={openDialogueBox}
          handleDialogueBox={handleDialogueBox}
          messageType={message?.type}
          message={message?.text}
        />
        {/* Message Dialogue Box */}
      </div>
    </Spin>
  );
};

export default ERetailFSAPage;
