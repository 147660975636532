import {
    ACCOUNTS,
    ADMIN,
    BASE_URL,
    BEYOND_CORE_ASSORTMENT,
    CATEGORY_BRAND_MAPPING,
    COUNTRIES,
    DATES,
    EDGE_ECOMM,
    INPUT_FORMS,
    KBIS,
    RAWDATA,
    UPLOAD_BC_BP,
    UPLOAD_NSR_BP,
    UPLOAD_VOLUME_BP,
    USERS
} from "../../constant/apiEndpoints";
import {
    RESET_DATA_E_RETAIL,
    SET_BEYOND_CORE_KBI_INPUTS,
    SET_BEYOND_CORE_KBI_INPUTS_PREV_PERIOD,
    SET_BEYOND_CORE_KBIS,
    SET_BEYOND_CORE_KBIS_LOADING,
    SET_BEYOND_CORE_MASTER_LIST,
    SET_BEYOND_CORE_MASTER_LIST_LOADING,
    SET_BEYOND_CORE_RAW_TABLE,
    SET_BEYOND_CORE_RAW_TABLE_LOADING,
    SET_BP_DATA,
    SET_COUNTRIES,
    SET_COUNTRIES_LOADING,
    SET_KBI_COMMENT,
    SET_KBI_INPUT_VALUES,
    SET_KBI_INPUT_VALUES_LOADING,
    SET_KBI_INPUT_VALUES_PREV_PERIOD,
    SET_KBI_MASTER,
    SET_KBI_MASTER_LOADING,
    SET_KBI_MASTER_UNEDITED,
    SET_LAST_EDITED_BY,
    SET_MESSAGE,
    SET_PERIODS,
    SET_PERIODS_LOADING,
    UPLOADING_BP_DATA
} from "../../reducer/ECommRetail/constants"

import * as utils from './utils';

import { IBeyondRawData, IBeyondCoreKBI, IBeyondCoreRawKBI, IDateListItem, IKBI, IKBIBrandCategory, IMessage, KBIInputState, INSR_VolumeData, IBeyondCoreData, IBeyondCoreMasterList } from "../../reducer/ECommRetail/_interfaces";
import { IAPIReturn } from "../../services/_interfaces";
import APIService from "../../services/APIService";

export const clearBP = () => ({ type: SET_BP_DATA, payload: null });

export const clearData = () => ({type: RESET_DATA_E_RETAIL})
export const setBeyondCore = (UpdatedData:IBeyondCoreMasterList) => ({ type: SET_BEYOND_CORE_MASTER_LIST, payload: UpdatedData });
export const setMessage = (message:IMessage | null) => ({type: SET_MESSAGE, payload: message});
export const setKBIUnedited = (kbis:KBIInputState) => ({ type: SET_KBI_MASTER_UNEDITED, payload: kbis });
export const setBeyondCoreKBIInputs = (kbis:KBIInputState) => ({ type: SET_BEYOND_CORE_KBI_INPUTS, payload: kbis });
export const setBeyondCoreKBIInputsPrevPeriod = (kbis:KBIInputState) => ({ type: SET_BEYOND_CORE_KBI_INPUTS_PREV_PERIOD, payload: kbis });
export const setComment = (comment:string) => ({ type: SET_KBI_COMMENT, payload: comment });
export const setLastEditedBy = (value:{lastUpdatedAt:string, lastAddedBy:string}) => ({ type: SET_LAST_EDITED_BY, payload: value });
export const setKBIInputValues = (kbis:KBIInputState) => ({ type: SET_KBI_INPUT_VALUES, payload: kbis });
export const setKBIInputValuesPrevPeriod = (kbis:KBIInputState) => ({ type: SET_KBI_INPUT_VALUES_PREV_PERIOD, payload: kbis });
export const setUploading = (val:boolean) => ({ type: UPLOADING_BP_DATA, payload: val });

export const loadCountries = (formId:string) => async (dispatch:any) => {
    dispatch({type: SET_COUNTRIES_LOADING, payload: true});
    const url = BASE_URL + ACCOUNTS + USERS + COUNTRIES + `?form_id=${formId}`;

    const response:IAPIReturn = await new APIService().get(url);

    if (response.error) {
        console.log("error in user countrylist API", response.results.message);
        dispatch(setMessage({type: 'Error', text: response.results.message}));
    } else {
        dispatch({type: SET_COUNTRIES, payload: response.results});
    }
    
    dispatch({type: SET_COUNTRIES_LOADING, payload: false});
}

export const loadPeriods = () => async (dispatch:any) => {
    dispatch({type: SET_PERIODS_LOADING, payload: true});
    const url = BASE_URL + ACCOUNTS + DATES;

    const response:IAPIReturn = await new APIService().get(url);

    if (response.error) {
        console.log("error in datelist API", response.results.message);
        dispatch(setMessage({type: 'Error', text: response.results.message}));
    } else {
        dispatch({type: SET_PERIODS, payload: response.results});
    }
    
    dispatch({type: SET_PERIODS_LOADING, payload: false});
}

export const loadKBIMaster = (formId:string) => async (dispatch:any) => {
    dispatch({type: SET_KBI_MASTER_LOADING, payload: true});
    const url =
        BASE_URL +
        INPUT_FORMS +
        EDGE_ECOMM +
        KBIS +
        `?selected_form=${formId}`;
      
    let response:IAPIReturn = await new APIService().get(url);
    
    if (response.error) {
            console.log("error in user datelis API", response.results.message);
            dispatch(setMessage({type: 'Error', text: response.results.message}));
      } else {
            const data:IKBI[] = response.results.sort((a:IKBI, b:IKBI) => a.rank - b.rank);
            dispatch({ type: SET_KBI_MASTER, payload: data })

            let kbiss:KBIInputState = {}
            data.forEach((val) => { kbiss[val.kbi_id] = null; })
            dispatch(setKBIUnedited(kbiss))
      }

    
    dispatch({type: SET_KBI_MASTER_LOADING, payload: false});
}

export const loadBeyondCoreRawTable = (formId?:string) => async (dispatch:any) => {
    dispatch({type: SET_BEYOND_CORE_RAW_TABLE_LOADING, payload: true});
    const url =
        BASE_URL
        + INPUT_FORMS
        + EDGE_ECOMM
        + BEYOND_CORE_ASSORTMENT
        + RAWDATA
        + `?selected_form=${formId}`;
    const response:IAPIReturn = await new APIService().get(url);
    
    if (response.error) {
        console.log("error in user datelis API", response.results.message);
        dispatch(setMessage({type: 'Error', text: 'Failed to load Beyond the core assortment raw data'}));
    } else {
        dispatch({ type: SET_BEYOND_CORE_RAW_TABLE, payload: response.results });
    }
    
    dispatch({type: SET_BEYOND_CORE_RAW_TABLE_LOADING, payload: false});
}

export const loadData = (periodId:string, countryId:string, countryTitle:string, periodTitle:string, formId:string, periods:IDateListItem[], beyondRawTable:IBeyondRawData[], kbiMaster:IKBI[]) => async (dispatch:any) => {
    dispatch({ type: SET_BEYOND_CORE_MASTER_LIST_LOADING, payload: true });
    dispatch({ type: SET_KBI_INPUT_VALUES_LOADING, payload: true });

    const selected_date = periods.findIndex((x) => x.id === parseInt(periodId));

    const {
        categoryBrandURL,
        beyondCoreAssortmentURL,
        beyondCoreAssortmentPrevPeriodURL,
        kbiURL,
        kbiPrevPeriodURL,
        businessPlanURL
    } = utils.createLoadDataEndpoints(formId, periodId, countryId, periods);

    await new APIService()
        .get(businessPlanURL)
        .then((response:IAPIReturn) => {
            if (response.error) {
                console.log("error in kbi BP API", response.results.message);
                dispatch(setMessage({type: 'Error', text: response.results.message}));
            } else {
                const beyond_core = response.results.beyond_core.reduce((a:any, e:IBeyondCoreData) => ({
                    ...a,
                    [e.kbi]: e
                }), {});

                const nsrData = response.results.nsr.reduce((a:any, e:INSR_VolumeData) => ({
                    ...a,
                    [e.kbi]: e
                }), {});

                const volumeData = response.results.volume.reduce((a:any, e:INSR_VolumeData) => ({
                    ...a,
                    [e.kbi]: e
                }), {});

                dispatch({ type: SET_BP_DATA, payload: {
                    ...nsrData,
                    ...volumeData,
                    ...beyond_core,
                } })
            }
        })
    
    await new APIService()
        .get(categoryBrandURL)
        .then(async (response:IAPIReturn) => {
            if (response.error) {
                console.log("error in kbivalues API", response.results.message);
                dispatch(setMessage({type: 'Error', text: response.results.message}));
            } else if (response.results.length > 0 && response.results[0].comment !== null) {
                let data:IKBIBrandCategory[] = response.results;
            
                const UpdatedData = data.reduce((acc:any, curr:IKBIBrandCategory) => {
                    if (!acc[curr.category_id]) {
                        acc[curr.category_id] = [];
                    }
                    acc[curr.category_id].push(curr);

                    return acc;
                }, {});

                dispatch(setBeyondCore(UpdatedData));

                let kbiState:KBIInputState = {};
                let kbiStateOld:KBIInputState = {};
                data.forEach((kbi) => {
                    kbiState[kbi.kbi_id] = null;
                    kbiStateOld[kbi.kbi_id] = null;
                });

                const beyondCoreValues:IAPIReturn = await new APIService().get(beyondCoreAssortmentURL);

                if (beyondCoreValues.error) {
                    console.log("error in kbivalues API", response.results.message);
                    dispatch(setMessage({type: 'Error', text: response.results.message}));
                } else {
                    kbiState = utils.createBeyondKBIInputValues(
                        data,
                        periodTitle,
                        countryTitle,
                        beyondCoreValues?.results,
                        kbiState,
                        beyondRawTable
                    )
                }
                
                if (periods[selected_date + 1]) {
                    const beyondCoreValuesPrevPeriod:IAPIReturn = await new APIService().get(beyondCoreAssortmentPrevPeriodURL);
                    if (beyondCoreValuesPrevPeriod.error) {
                        console.log("error in kbivalues API", response.results.message);
                        dispatch(setMessage({type: 'Error', text: response.results.message}));
                    } else {
                        kbiStateOld = utils.createBeyondKBIInputValues(
                            data,
                            periodTitle,
                            countryTitle,
                            beyondCoreValuesPrevPeriod?.results,
                            kbiStateOld,
                            beyondRawTable
                        )
                    }
                }

                dispatch(setBeyondCoreKBIInputs(kbiState));
                dispatch(setBeyondCoreKBIInputsPrevPeriod(kbiStateOld));
    
            } else {
                dispatch(setBeyondCoreKBIInputs({}));
                dispatch(setBeyondCoreKBIInputsPrevPeriod({}));
            }
        }).finally(() => {
            dispatch({ type: SET_BEYOND_CORE_MASTER_LIST_LOADING, payload: false });
        });

    
    await new APIService().get(kbiURL).then(async (response:IAPIReturn) => {
        if (response.error) {
            console.log("error in kbivalues API", response.results.message);
            dispatch(setMessage({type: 'Error', text: response.results.message}));
        } else {
            const {
                lastSubmittedAt,
                lastSubmittedBy,
                comment,
                kbiState,
            } = utils.createKBIInputValues(response.results, kbiMaster);
            
            dispatch(setComment(comment || ''));
            dispatch(setLastEditedBy({ lastAddedBy: lastSubmittedBy, lastUpdatedAt: lastSubmittedAt }));
            dispatch(setKBIInputValues(kbiState));

            await new APIService().get(kbiPrevPeriodURL).then((res:IAPIReturn) => {
                if (response.error) {
                    console.log("error in kbivalues API", response.results.message);
                    dispatch(setMessage({type: 'Error', text: response.results.message}));
                } else {
                    const { kbiState } = utils.createKBIInputValues(res.results, kbiMaster);
                    dispatch(setKBIInputValuesPrevPeriod(kbiState));
                }
            })
        }
    }).finally(() => {
        dispatch({ type: SET_KBI_INPUT_VALUES_LOADING, payload: false });
    });
}

export const loadBeyondCoreMap = () => async (dispatch:any) => {
    try {
        dispatch({type: SET_BEYOND_CORE_KBIS_LOADING, payload: true})
        const url =
            BASE_URL +
            INPUT_FORMS +
            EDGE_ECOMM +
            CATEGORY_BRAND_MAPPING +
            ADMIN +
            `?selected_form=formecomm`;

        await new APIService().get(url).then((response:IAPIReturn) => {
            if (response.error) {
                console.log("error in user datelis API", response.results.message);
                dispatch(setMessage({type: 'Error', text: 'Failed to load Beyond the core assortment raw data'}));
            } else {
                const payload = response.results.reduce((acc:IBeyondCoreKBI, curr:IBeyondCoreRawKBI) => ({
                    ...acc,
                    [curr.kbi_id]: curr,
                }), {})
                dispatch({ type: SET_BEYOND_CORE_KBIS, payload });
            }
            dispatch({type: SET_BEYOND_CORE_KBIS_LOADING, payload: false})
        })
    } catch (e:any) {
        dispatch({type: SET_BEYOND_CORE_KBIS_LOADING, payload: false})
    }
}

export const uploadBP = (nsr:INSR_VolumeData[], volume:INSR_VolumeData[], beyondCore:IBeyondCoreData[]) => async (dispatch:any) => {
    try {
        dispatch(setUploading(true));

        const nsrURL = 
            BASE_URL
            + INPUT_FORMS
            + EDGE_ECOMM
            + UPLOAD_NSR_BP +
            `?selected_form=formecomm`;

        const volumeURL =
            BASE_URL
            + INPUT_FORMS
            + EDGE_ECOMM
            + UPLOAD_VOLUME_BP +
            `?selected_form=formecomm`;

            
        const beyondCoreURL =
            BASE_URL
            + INPUT_FORMS
            + EDGE_ECOMM
            + UPLOAD_BC_BP +
            `?selected_form=formecomm`;

            if (nsr.length > 0) {
                await new APIService().post(nsrURL, nsr.filter((n) => n.kbi !== "totalNSR")).then((response:IAPIReturn) => {
                    if (response.error) {
                        console.log("error in NSR upload API", response.results.message);
                        dispatch(setMessage({type: 'Error', text: 'Error uploading NSR data'}));
                    }
                })
            }

            if (volume.length > 0) {
                await new APIService().post(volumeURL, volume.filter((n) => n.kbi !== "totalVolume")).then((response:IAPIReturn) => {
                    if (response.error) {
                        console.log("error in Volume upload API", response.results.message);
                        dispatch(setMessage({type: 'Error', text: 'Error uploading Volume data'}));
                    }
                })
            }

            if (beyondCore.length > 0) {
                await new APIService().post(beyondCoreURL, beyondCore).then((response:IAPIReturn) => {
                    if (response.error) {
                        console.log("error in Beyond core upload API", response.results.message);
                        dispatch(setMessage({type: 'Error', text: 'Error uploading Beyond core data'}));
                    }
                })
            }
    } catch (error:any) {

    } finally {
        dispatch(setUploading(false));
    }
} 

export const uploadNSRBP = (data:INSR_VolumeData[]) => async (dispatch:any) => {
    try {
        dispatch(setUploading(true));
        const url =
            BASE_URL
            + INPUT_FORMS
            + EDGE_ECOMM
            + UPLOAD_NSR_BP +
            `?selected_form=formecomm`;

        await new APIService().post(url, data).then((response:IAPIReturn) => {
            if (response.error) {
                console.log("error in NSR upload API", response.results.message);
                dispatch(setMessage({type: 'Error', text: 'Error uploading NSR data'}));
            }
        }).finally(() => {
            dispatch(setUploading(false));
        })
    } catch (error:any) {
        dispatch(setUploading(false));
    }
}
export const uploadVolumeBP = (data:INSR_VolumeData[]) => async (dispatch:any) => {
    try {
        dispatch(setUploading(true));
        const url =
            BASE_URL
            + INPUT_FORMS
            + EDGE_ECOMM
            + UPLOAD_VOLUME_BP +
            `?selected_form=formecomm`;
        await new APIService().post(url, data).then((response:IAPIReturn) => {
            if (response.error) {
                console.log("error in Volume upload API", response.results.message);
                dispatch(setMessage({type: 'Error', text: 'Error uploading Volume data'}));
            }
        }).finally(() => {
            dispatch(setUploading(false));
        })
    } catch (error:any) {
        dispatch(setUploading(false));
    }
}
export const uploadBCBP = (data:IBeyondCoreData[]) => async (dispatch:any) => {
    try {
        dispatch(setUploading(true));
        const url =
            BASE_URL
            + INPUT_FORMS
            + EDGE_ECOMM
            + UPLOAD_BC_BP +
            `?selected_form=formecomm`;

        await new APIService().post(url, data).then((response:IAPIReturn) => {
            if (response.error) {
                console.log("error in Beyond core upload API", response.results.message);
                dispatch(setMessage({type: 'Error', text: 'Error uploading Beyond core data'}));
            }
        }).finally(() => {
            dispatch(setUploading(false));
        })
    } catch (error:any) {
        dispatch(setUploading(false));
    }
}